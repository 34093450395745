import React, { useEffect, useState } from "react";
import _ from "lodash";
import PayrollEntry from "./PayrollEntry";
import NewEmployeeButton from "./NewEmployeeButton";
import DeleteEmployeeButton from "./DeleteEmployeeButton";
import store from "./store";
import { setInitialPayroll, setOrderByOnEntries } from "./actions";
import { newTotal } from "./reducers/payroll";

const TABLE_HEAD = {
  name: "Employee Name",
  hours: "Hours",
  wage_salary: "Wage / Sal.",
  tip: "Tip",
  bonus: "Bonus",
  comm: "Comm",
  discretionary_bonus: "D.Bonus",
  spiff: "SPIFF",
  star: "Star",
  refund: "Refund",
  total: "Total",
  _: "",
};

const isEditingButtons = (entries) => {
  const selectedEntryToDelete = entries.some(
    (entry) => entry.toDelete === true
  );

  return (
    <tr>
      <td colSpan="3">
        <div className="add-employee-btn">
          <NewEmployeeButton />
        </div>
      </td>
      {selectedEntryToDelete && (
        <td colSpan="9" style={{ textAlign: "right" }}>
          <DeleteEmployeeButton />
        </td>
      )}
    </tr>
  );
};

const Payroll = (props) => {
  const [orderByField, setOrderByField] = useState(null);
  const [orderByDirection, setOrderByDirection] = useState(null);
  const [entries, setEntries] = useState(props.entries);
  const [isEditing, setIsEditing] = useState(false);
  const [payroll, setPayroll] = useState(null);
  const [total, setTotal] = useState(newTotal(props.entries));

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const payroll = store.getState().payroll;
      setPayroll(payroll);
      setEntries(payroll.entries);
      setIsEditing(payroll.isEditing);
      setTotal(payroll.total);
    });

    store.dispatch(setInitialPayroll(props.payroll_id, props.entries));

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (payroll) {
      setOrderByField(payroll.orderByField);
      setOrderByDirection(payroll.orderByDirection);
    }
  }, [payroll]);

  useEffect(() => {
    setEntries(entries);
    setTotal(newTotal(entries));
  }, [entries]);

  useEffect(() => {
    setIsEditing(isEditing);
  }, [isEditing]);

  const clickOrderBy = (key) => {
    store.dispatch(setOrderByOnEntries({ field: key }));
  };

  const headLabelWithOrder = (key, label) => {
    return (
      <>
        {label}
        {orderByField === `entry.${key}` && orderByDirection === "asc" && "▲"}
        {orderByField === `entry.${key}` && orderByDirection === "desc" && "▼"}
      </>
    );
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {Object.entries(TABLE_HEAD).map(([key, label]) => (
            <th
              scope="col"
              key={key}
              onClick={() => clickOrderBy(key)}
              style={{ cursor: "pointer" }}
            >
              {headLabelWithOrder(key, label)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {entries.map((es) => (
          <PayrollEntry
            key={es.entry.key}
            {...es}
            isEditing={isEditing}
            disabled={props.disabled}
          />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>Total</td>
          <td>{total.hours.toFixed(2)}</td>
          <td>{total.wage_salary.toFixed(5)}</td>
          <td>{total.tip.toFixed(2)}</td>
          <td>{total.bonus.toFixed(2)}</td>
          <td>{total.comm.toFixed(2)}</td>
          <td>{total.discretionary_bonus.toFixed(2)}</td>
          <td>{total.spiff.toFixed(2)}</td>
          <td>{total.star.toFixed(2)}</td>
          <td>{total.refund.toFixed(2)}</td>
          <td>{total.total.toFixed(2)}</td>
          <td></td>
        </tr>
        {isEditing && isEditingButtons(entries)}
      </tfoot>
    </table>
  );
};

export default Payroll;
