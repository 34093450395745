// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("bootstrap")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener("DOMContentLoaded", function () {
  const autohideElement = document.querySelector(".autohide");

  const navbarHeight = document.querySelector(".navbar").offsetHeight;
  document.body.style.paddingTop = navbarHeight + "px";

  if (autohideElement) {
    var lastScrollTop = 0;
    window.addEventListener("scroll", function () {
      let scrollTop = window.scrollY;
      if (scrollTop < lastScrollTop) {
        autohideElement.classList.remove("scrolled-down");
        autohideElement.classList.add("scrolled-up");
      } else {
        autohideElement.classList.remove("scrolled-up");
        autohideElement.classList.add("scrolled-down");
      }
      lastScrollTop = scrollTop;
    });
  }
});
