import React from "react"

class CompanySelect extends React.Component {
  handleChange(evt) {
    const resource = location.pathname.split('/')[1];

    if (resource === 'companies') {
      const [_, currentPath] = location.pathname.match(/\/companies\/[\w-]+\/(.*)/);
      return document.location.href = `/companies/${evt.target.value}/${currentPath}`;
    }
    else if (resource === 'employees') {
      const basePath = '/employees/payrolls';
      return document.location.href = evt.target.value ? `${basePath}?company_id=${evt.target.value}` : basePath;
    };
  }

  company(c) {
    return <option key={c.id} value={c.id}>{c.name}</option>;
  }

  render() {
    return (
      <select className="custom-select" defaultValue={this.props.selected} onChange={ this.handleChange }>
        <option value={""}>All Companies</option>
        { this.props.companies.map((c) => this.company(c) ) }
      </select>
    );
  }
}

export default CompanySelect;
