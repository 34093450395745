import {
  LOAD_EMPLOYEE_LIST,
  UPDATE_EMPLOYEE_LIST,
  CLEAR_EMPLOYEE_LIST
} from "../actionTypes";

import { SLEEPING, LOADING, LOADED } from "../stateTypes";

const initialState = { state: SLEEPING, list: [], original: [] };

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_EMPLOYEE_LIST: {
      return {
        ...state,
        state: LOADING
      };
    }
    case UPDATE_EMPLOYEE_LIST: {
      var newList = [];

      // only keep usefull data
      action.payload.employees.forEach((i) => newList.push({ id: i.id, name: i.name }) )

      var newState = {
        ...state,
        list: newList,
      };

      if(action.payload.updateOriginal){
        newState.original = newList.slice();
        newState.state = LOADED;
      }

      return newState;
    }
    case CLEAR_EMPLOYEE_LIST: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};
