import React, { useCallback } from "react";
import classnames from "classnames";
import store from "./store";
import { deletePayrollEntry } from "./actions";

const DeleteEmployeeButton = () => {

  const entriesToDelete = useCallback(() => {
    const {
      payroll: {
        entries
      }
    } = store.getState();

    return entries.filter(entry => entry.toDelete === true);
  }, []);

  const deleteEntries = useCallback((e) => {
    e.preventDefault();

    entriesToDelete().map(entry => store.dispatch(deletePayrollEntry({ key: entry.key, id: entry.entry.id })))
  }, []);

  return (
    <button
      className={classnames("btn btn-danger btn-mini")}
      onClick={deleteEntries}
    >
      Delete selected
    </button>
  );
}

export default DeleteEmployeeButton;
