import {
  NEW_PAYROLL_ENTRY,
  SET_INITIAL_PAYROLL,
  START_PAYROLL_SAVE,
  FINISH_PAYROLL_SAVE,
  UPDATE_PAYROL_ENTRY_IN_LIST,
  DELETE_PAYROL_ENTRY,
  REMOVE_FROM_ENTRIES_LIST,
  LOAD_EMPLOYEE_LIST,
  UPDATE_EMPLOYEE_LIST,
  CLEAR_EMPLOYEE_LIST,
  SET_EDITING_PAYROLL_ENTRIES,
  SET_ORDER_BY_ON_ENTRIES,
} from "./actionTypes";

import store from "./store";
import axios from "axios";
import jsSHA from "jssha";

export const newPayrollEntry = () => {
  return {
    type: NEW_PAYROLL_ENTRY,
  };
};

var lastEmployeeSha = "";

store.subscribe(() => {
  var state = store.getState();
  var employees_id = state.payroll.entries
    .map((i) => i.entry.employee_id)
    .filter((i) => i != null);

  var shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });

  shaObj.update(employees_id.join(""));
  state.employee.original.forEach((i) => shaObj.update(i.id));

  var hash = shaObj.getHash("HEX");

  if (hash != lastEmployeeSha) {
    lastEmployeeSha = hash;
    store.dispatch(filterEmployeeListByEmployeeIds(employees_id));
  }
});

export const setInitialPayroll = (payroll_id, entries) => {
  return {
    type: SET_INITIAL_PAYROLL,
    payload: {
      payroll_id,
      entries,
    },
  };
};

const saveEntry = async (entry) => {
  let error;

  try {
    const { data } = entry.id
      ? await updateEntry(entry)
      : await createEntry(entry);

    entry.id = data.entry.id;
  } catch (err) {
    error = err.response.data.meta.errors.entry;
  }

  return [entry, error];
};

const urlBase = () => {
  return location.pathname.replace(/(companies\/[\w-]+).*/, "$1");
};

const createEntry = (entry) => {
  return axios.post(`${urlBase()}/payroll/entries`, { entry: entry });
};

const updateEntry = (entry) => {
  return axios.patch(`${urlBase()}/payroll/entries/${entry.id}`, {
    entry: entry,
  });
};

const getEmployee = (filter) => {
  var params = {};
  if (filter) params.q = filter;
  return axios
    .get(`${urlBase()}/employees.json`, params)
    .then((res) => res.data.employees);
};

const deleteEntry = (payload) => {
  if (!payload.id) {
    store.dispatch(removeFromEntriesList(payload.key));
    return;
  }

  axios
    .delete(`${urlBase()}/payroll/entries/${payload.id}`)
    .then((_res) => store.dispatch(removeFromEntriesList(payload.key)));
};

export const updateEmployeeList = (employees, updateOriginal = false) => {
  return {
    type: UPDATE_EMPLOYEE_LIST,
    payload: { employees, updateOriginal },
  };
};

export const loadEmployeeList = (filter) => {
  getEmployee(filter)
    .then((employees) => store.dispatch(updateEmployeeList(employees, true))) // updateOriginal: true
    .catch((err) => console.log(err));

  return {
    type: LOAD_EMPLOYEE_LIST,
    payload: {},
  };
};

export const filterEmployeeListByEmployeeIds = (employee_ids) => {
  var original = store.getState().employee.original;
  var newList = original.filter((e) => employee_ids.indexOf(e.id) == -1);

  return updateEmployeeList(newList);
};

export const clearEmployeeList = () => {
  return {
    type: CLEAR_EMPLOYEE_LIST,
    payload: {},
  };
};

export const savePayrollEntries = (payroll, entries) => {
  store.dispatch(startPayrollSave(payroll));

  Promise.all(entries.map(({ entry }) => saveEntry(entry))).then((entries) => {
    let hasError = false;

    entries.forEach(([entry, error]) => {
      if (error) hasError = true;

      store.dispatch(
        updatePayrollEntry({
          key: entry.key,
          entry,
          errors: error || [],
        })
      );
    });

    store.dispatch(setEditingPayrollEntries(hasError));
    store.dispatch(finishPayrollSave(payroll));
  });
};

const finishPayrollSave = (payload) => {
  return {
    type: FINISH_PAYROLL_SAVE,
    payload: payload,
  };
};

const startPayrollSave = (payload) => {
  return {
    type: START_PAYROLL_SAVE,
    payload: payload,
  };
};

export const updatePayrollEntry = (payload) => {
  return {
    type: UPDATE_PAYROL_ENTRY_IN_LIST,
    payload: payload,
  };
};

export const setEditingPayrollEntries = (payload) => {
  return {
    type: SET_EDITING_PAYROLL_ENTRIES,
    payload,
  };
};

export const deletePayrollEntry = (payload) => {
  deleteEntry(payload);
  return {
    type: DELETE_PAYROL_ENTRY,
    payload: payload,
  };
};

export const removeFromEntriesList = (key) => {
  return {
    type: REMOVE_FROM_ENTRIES_LIST,
    payload: { key: key },
  };
};

export const setOrderByOnEntries = (payload) => {
  return {
    type: SET_ORDER_BY_ON_ENTRIES,
    payload: payload,
  };
};
