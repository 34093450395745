import React from "react"
import { Combobox } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';
import { LOADING, SLEEPING } from './stateTypes';
import store from "./store";
import { loadEmployeeList } from "./actions"

class EmployeeSelect extends React.Component {
  state = store.getState().employee;
  unsubscribe = null;

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState(store.getState().employee);
    });

    if(this.state.state == SLEEPING){
      store.dispatch(loadEmployeeList());
    }
  }

  componentWillUnmount() {
    if(this.unsubscribe) this.unsubscribe();
  }

  isLoading(){
    return this.state.state == LOADING;
  }

  render(){
    return (
      <Combobox
        data={this.state.list}
        containerClassName="formControl"
        className="name"
        textField="name"
        valueField="id"
        defaultValue={this.props.value }
        onChange={ this.props.onChange }
        busy= { this.isLoading() } />
    );
  }
}

export default EmployeeSelect;
