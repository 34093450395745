import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";

import store from "./store";
import { SAVING, SLEEPING } from "./stateTypes";
import { savePayrollEntries, setEditingPayrollEntries } from "./actions";

const EditPayrollEntries = () => {
  const [payroll, setPayroll] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState(SLEEPING);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const payroll = store.getState().payroll;
      setPayroll(payroll);
      setIsEditing(payroll.isEditing);
      setState(payroll.state);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    setIsEditing(isEditing);

    // Today, this button is out of react components, so
    // we use 'normal' html to change this button behavior
    const sendPayrollButton = document.getElementById("send-payroll-button");
    if (isEditing) {
      sendPayrollButton.style.display = "none";
    } else {
      sendPayrollButton.style.display = "inline-block";
    }
  }, [isEditing]);

  useEffect(() => {
    setIsLoading(state === SAVING);
  }, [state]);

  const entriesToRequest = useCallback((entries) => {
    return entries.filter((entry) => entry.toDelete === false);
  }, []);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      const { isEditing, entries } = payroll;

      if (isEditing) {
        setIsLoading(true);
        savePayrollEntries(payroll, entriesToRequest(entries));
      } else {
        store.dispatch(setEditingPayrollEntries(true));
      }
    },
    [payroll]
  );

  return (
    <button
      className={classnames("btn btn-info btn-lg")}
      onClick={handleClick}
      type="button"
      disabled={isLoading}
    >
      {payroll.isEditing ? "SAVE" : "EDIT"}

      {isLoading && (
        <span
          className={classnames("spinner-grow spinner-grow-sm")}
          role="status"
        ></span>
      )}
    </button>
  );
};

export default EditPayrollEntries;
