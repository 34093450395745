import React from "react";

import classnames from "classnames";
import EmployeeSelect from "./EmployeeSelect";
import store from "./store";
import { updatePayrollEntry } from "./actions";

class PayrollEntry extends React.Component {
  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.entry.total !== this.props.entry.total) {
      const entry = { key: this.props.entry.key };
      entry.total = this.calculateTotalSalary(entry);

      store.dispatch(updatePayrollEntry({ entry }));
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    var entry = { key: this.props.entry.key };
    entry[name] = value;
    entry.total = this.calculateTotalSalary(entry);

    store.dispatch(updatePayrollEntry({ entry }));
  };

  onKeyPress = (event) => {
    const {
      target: { name },
      key,
    } = event;

    let regex = /[0-9.]/g;

    const fieldsAllowNegativeNumber = ["discretionary_bonus", "refund"];
    if (fieldsAllowNegativeNumber.includes(name))
      regex = /[0-9.-]/g;

    if (!regex.test(key)) event.preventDefault();
  };

  calculateTotalSalary(entry) {
    const sumFields = [
      "tip",
      "bonus",
      "comm",
      "discretionary_bonus",
      "spiff",
      "star",
      "refund",
    ];

    let totalSalary =
      this.fetch("hours", entry) * this.fetch("wage_salary", entry);

    sumFields.forEach((field) => (totalSalary += this.fetch(field, entry)));
    return totalSalary.toFixed(2);
  }

  changeName() {
    return (value) => {
      if (typeof value == "string") {
        var value = { id: null, name: value };
      }

      var entry = {
        key: this.props.entry.key,
        employee_id: value.id,
        name: value.name,
      };

      store.dispatch(updatePayrollEntry({ entry }));
    };
  }

  fetch(name, entry) {
    return this.floatOrZero(entry[name] || this.props.entry[name]);
  }

  floatOrZero(val) {
    return parseFloat(val) || 0;
  }

  numericOnly(val, field) {
    let regex = /[^\d\.]/g;

    const fieldsAllowNegativeNumber = ["discretionary_bonus", "refund"];
    if (fieldsAllowNegativeNumber.includes(field)) regex = /[^-\d\.]/g;

    return (val || 0).toString().replace(regex, "");
  }

  joinErrors(errors, field) {
    if (!errors[field]) return "";
    return errors[field].map((i) => i.message).join(", ");
  }

  hasError(name) {
    return this.props.errors[name] != null;
  }

  inputField(name, value, klass) {
    klass = klass || name;

    if (!this.props.isEditing)
      return (
        <input
          className={classnames("form-control", klass)}
          disabled={true}
          value={parseFloat(value).toFixed(name === "wage_salary" ? 5 : 2)}
          type="text"
        />
      );

    return (
      <input
        className={classnames("form-control", klass, {
          "is-invalid": this.hasError(name),
        })}
        onChange={this.handleChange}
        value={value}
        name={name}
        onKeyPress={this.onKeyPress}
        type="text"
      />
    );
  }

  inputCheckboxField = (e) => {
    store.dispatch(
      updatePayrollEntry({
        key: this.props.entry.key,
        toDelete: e.target.checked,
      })
    );
  };

  render() {
    return (
      <tr
        className={classnames({
          saved: this.props.entry.id,
          "new-employee": !this.props.entry.id,
        })}
      >
        <td>
          {!this.props.isEditing ? (
            this.props.entry.name
          ) : (
            <EmployeeSelect
              onChange={this.changeName()}
              value={{
                id: this.props.entry.employee_id,
                name: this.props.entry.name,
              }}
            />
          )}

          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "name")}
          </div>
        </td>
        <td>
          {this.inputField("hours", this.props.entry.hours)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "hours")}
          </div>
        </td>
        <td>
          {this.inputField("wage_salary", this.props.entry.wage_salary, "wage")}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "wage_salary")}
          </div>
        </td>
        <td>
          {this.inputField("tip", this.props.entry.tip)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "tip")}
          </div>
        </td>
        <td>
          {this.inputField("bonus", this.props.entry.bonus)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "bonus")}
          </div>
        </td>
        <td>
          {this.inputField("comm", this.props.entry.comm)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "comm")}
          </div>
        </td>
        <td>
          {this.inputField(
            "discretionary_bonus",
            this.props.entry.discretionary_bonus
          )}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "discretionary_bonus")}
          </div>
        </td>
        <td>
          {this.inputField("spiff", this.props.entry.spiff)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "spiff")}
          </div>
        </td>
        <td>
          {this.inputField("star", this.props.entry.star)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "star")}
          </div>
        </td>
        <td>
          {this.inputField("refund", this.props.entry.refund)}
          <div className="invalid-feedback">
            {this.joinErrors(this.props.errors, "refund")}
          </div>
        </td>
        <td className="total">
          {parseFloat(this.props.entry.total).toFixed(2)}
        </td>
        <td>
          {this.props.isEditing && (
            <input type="checkbox" onChange={this.inputCheckboxField} />
          )}
        </td>
      </tr>
    );
  }
}

// EditPayrollEntry.propTypes = {
//   id: PropTypes.node,
//   hours: PropTypes.number,
//   wage_salary: PropTypes.number,
//   tip: PropTypes.tip,
//   total: PropTypes.number
// };

export default PayrollEntry;
