import {
  NEW_PAYROLL_ENTRY,
  SET_INITIAL_PAYROLL,
  START_PAYROLL_SAVE,
  FINISH_PAYROLL_SAVE,
  UPDATE_PAYROL_ENTRY_IN_LIST,
  DELETE_PAYROL_ENTRY,
  REMOVE_FROM_ENTRIES_LIST,
  SET_EDITING_PAYROLL_ENTRIES,
  SET_ORDER_BY_ON_ENTRIES,
} from "../actionTypes";

import { SLEEPING, SAVING, DELETING } from "../stateTypes";
import _ from "lodash";
import deepmerge from "deepmerge";

const initialState = {
  state: SLEEPING,
  payroll_id: null,
  entries: [],
  total: {
    hours: 0,
    wage_salary: 0,
    tip: 0,
    total: 0,
    bonus: 0,
    comm: 0,
    discretionary_bonus: 0,
    spiff: 0,
    star: 0,
    refund: 0,
  },
  entriesKeyIdx: {},
  isEditing: false,
};

const newEntryTemplate = {
  key: null,
  id: null,
  name: "",
  hours: 0,
  wage_salary: 0,
  tip: 0,
  total: 0,
  bonus: 0,
  comm: 0,
  discretionary_bonus: 0,
  spiff: 0,
  star: 0,
  refund: 0,
  payroll_id: null,
};

export const newTotal = (entries) => {
  let newTotal = {
    hours: 0,
    wage_salary: 0,
    tip: 0,
    total: 0,
    bonus: 0,
    comm: 0,
    discretionary_bonus: 0,
    spiff: 0,
    star: 0,
    refund: 0,
  };

  entries.forEach(({ entry }) => {
    newTotal.hours += floatOrZero(entry.hours);
    newTotal.wage_salary += floatOrZero(entry.wage_salary);
    newTotal.tip += floatOrZero(entry.tip);
    newTotal.total += floatOrZero(entry.total);
    newTotal.bonus += floatOrZero(entry.bonus);
    newTotal.comm += floatOrZero(entry.comm);
    newTotal.discretionary_bonus += floatOrZero(entry.discretionary_bonus);
    newTotal.spiff += floatOrZero(entry.spiff);
    newTotal.star += floatOrZero(entry.star);
    newTotal.refund += floatOrZero(entry.refund);
  });

  return newTotal;
};

const floatOrZero = (val) => {
  return parseFloat(val) || 0;
};

const calculateTotalEntrySalary = (entry) => {
  const sumFields = [
    "tip",
    "bonus",
    "comm",
    "discretionary_bonus",
    "spiff",
    "star",
    "refund",
  ];

  let totalSalary = floatOrZero(entry.hours) * floatOrZero(entry.wage_salary);

  sumFields.forEach((field) => (totalSalary += floatOrZero(entry[field])));
  return totalSalary.toFixed(2);
};

const normalizeEntry = (entry) => {
  const { entry: data } = entry;

  const normalizedEntry = {
    ...entry,
    entry: {
      ...data,
      name: data.name.toUpperCase(),
      sortByname: data.name.toUpperCase(),
      hours: data.hours,
      sortByhours: floatOrZero(data.hours),
      wage_salary: data.wage_salary,
      sortBywage_salary: floatOrZero(data.wage_salary),
      tip: data.tip,
      sortBytip: floatOrZero(data.tip),
      bonus: data.bonus,
      sortBybonus: floatOrZero(data.bonus),
      comm: data.comm,
      sortBycomm: floatOrZero(data.comm),
      discretionary_bonus: data.discretionary_bonus,
      sortBydiscretionary_bonus: floatOrZero(data.discretionary_bonus),
      spiff: data.spiff,
      sortByspiff: floatOrZero(data.spiff),
      star: data.star,
      sortBystar: floatOrZero(data.star),
      refund: data.refund,
      sortByrefund: floatOrZero(data.refund),
      total: data.total,
      sortBytotal: floatOrZero(data.total),
    },
  };

  return normalizedEntry;
};

const normalizeAndSortEntries = (entries, orderByField, orderByDirection) => {
  const normalizedEntries = _.map(entries, normalizeEntry);
  return _.orderBy(
    normalizedEntries,
    orderByField.replace(".", ".sortBy"),
    orderByDirection
  );
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEW_PAYROLL_ENTRY: {
      const newEntry = {
        entry: {
          ...newEntryTemplate,
          payroll_id: state.payroll_id,
          key: Math.random(),
        },
        errors: {},
        toDelete: false,
      };
      let newEntries = state.entries.slice();
      newEntries.push(newEntry);

      const total = newTotal(newEntries);

      let entriesKeyIdx = {
        ...state.entriesKeyIdx,
      };

      entriesKeyIdx[newEntry.entry.key] = newEntries.length - 1;

      const newState = {
        ...state,
        entries: newEntries,
        total,
        entriesKeyIdx,
        isEditing: true,
      };

      return newState;
    }
    case SET_INITIAL_PAYROLL: {
      let entriesKeyIdx = {};
      const { payroll_id, entries } = action.payload;

      entries.forEach(({ entry }, idx) => {
        entriesKeyIdx[entry.key] = idx;
        entry.total = calculateTotalEntrySalary(entry);
      });

      const total = newTotal(entries);

      const newState = {
        ...state,
        payroll_id,
        entries,
        total,
        entriesKeyIdx,
      };

      return newState;
    }
    case START_PAYROLL_SAVE: {
      return {
        ...state,
        state: SAVING,
      };
    }
    case FINISH_PAYROLL_SAVE: {
      return {
        ...state,
        state: SLEEPING,
      };
    }
    case UPDATE_PAYROL_ENTRY_IN_LIST: {
      //{ key: entry.key, entry: res.data.entry, error: false }
      var key = action.payload.key || action.payload.entry.key;
      var idx = state.entriesKeyIdx[key];
      var entries = state.entries.slice();
      var entriesKeyIdx = { ...state.entriesKeyIdx };

      entries[idx] = deepmerge(entries[idx], action.payload);

      if (action.payload.key) {
        entriesKeyIdx[action.payload.key] = idx;
      }
      if (action.payload.entry) {
        entriesKeyIdx[action.payload.entry.key] = idx;
      }

      const total = newTotal(entries);

      return {
        ...state,
        entries,
        total,
        entriesKeyIdx,
      };
    }
    case DELETE_PAYROL_ENTRY: {
      return {
        ...state,
        state: DELETING,
      };
    }
    case REMOVE_FROM_ENTRIES_LIST: {
      var idx = state.entriesKeyIdx[action.payload.key];
      var entries = state.entries.slice();
      entries.splice(idx, 1);
      var entriesKeyIdx = {};
      const total = newTotal(entries);

      for (var idx = 0; idx < entries.length; idx++) {
        const entry = entries[idx].entry;
        entriesKeyIdx[entry.key] = idx;
      }

      return {
        ...state,
        state: SLEEPING,
        entries,
        total,
        entriesKeyIdx,
      };
    }
    case SET_EDITING_PAYROLL_ENTRIES: {
      return { ...state, isEditing: action.payload };
    }
    case SET_ORDER_BY_ON_ENTRIES: {
      let { orderByField, orderByDirection } = state;
      const newField = `entry.${action.payload.field}`;

      orderByField = newField === orderByField ? orderByField : newField;
      orderByDirection =
        newField === orderByField &&
        (orderByDirection === "desc" || !orderByDirection)
          ? "asc"
          : "desc";

      const entries = normalizeAndSortEntries(
        state.entries,
        orderByField,
        orderByDirection
      );

      let entriesKeyIdx = {};
      entries.forEach(({ entry }, idx) => {
        entriesKeyIdx[entry.key] = idx;
      });

      return {
        ...state,
        entries,
        entriesKeyIdx,
        orderByField,
        orderByDirection,
      };
    }
    default:
      return state;
  }
}
