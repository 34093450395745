import React from "react"
import classnames from "classnames";
import { newPayrollEntry } from "./actions"
import store from "./store";


class NewEmployeeButton extends React.Component {
  handleClick(e){
    e.preventDefault();
    store.dispatch(newPayrollEntry())
  }

  render(){
    return (
      <button className={ classnames("add-employee-btn", { invisible: this.props.disabled }) } onClick={ this.handleClick.bind(this) }>
        <span className="icon-plus">+</span>Add new employee
      </button>
    );
  }
}

export default NewEmployeeButton;
