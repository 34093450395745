export const NEW_PAYROLL_ENTRY = "NEW_PAYROLL_ENTRY";
export const SET_INITIAL_PAYROLL = "SET_INITIAL_PAYROLL";
export const SET_PAYROLL = "SET_PAYROLL";
export const START_PAYROLL_SAVE = "START_PAYROLL_SAVE";
export const FINISH_PAYROLL_SAVE = "FINISH_PAYROLL_SAVE";
export const UPDATE_PAYROL_ENTRY_IN_LIST = "UPDATE_PAYROL_ENTRY_IN_LIST";
export const DELETE_PAYROL_ENTRY = "DELETE_PAYROL_ENTRY";
export const REMOVE_FROM_ENTRIES_LIST = "REMOVE_FROM_ENTRIES_LIST";
export const LOAD_EMPLOYEE_LIST = "LOAD_EMPLOYEE_LIST";
export const UPDATE_EMPLOYEE_LIST = "UPDATE_EMPLOYEE_LIST";
export const CLEAR_EMPLOYEE_LIST = "CLEAR_EMPLOYEE_LIST";
export const SET_EDITING_PAYROLL_ENTRIES = "SET_EDITING_PAYROLL_ENTRIES";
export const SET_ORDER_BY_ON_ENTRIES = "SET_ORDER_BY_ON_ENTRIES";
