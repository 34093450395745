import React from "react";

class FormDatePicker extends React.Component {
  state = { date: "" };

  formattedDate = (rawDate) => {
    if (rawDate.length < 10) return;

    const splittedDate = rawDate.split("/");
    const parsedDate = new Date(
      parseInt(splittedDate[2], 10),
      parseInt(splittedDate[0], 10) - 1,
      parseInt(splittedDate[1], 10)
    );
    return parsedDate.toISOString().substring(0, 10);
  };

  handleChange = (event) => {
    const date = event.target.value;
    const formattedDate = this.isSafari() ? this.formattedDate(date) : date;
    this.setState({ date: formattedDate });
  };

  isSafari = () =>
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
    );

  pattern = () =>
    this.isSafari()
      ? null
      : "(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])dd$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)";

  render() {
    return (
      <span>
        <input type="hidden" name={this.props.name} value={this.state.date} />

        <input
          type="date"
          placeholder="mm/dd/yyyy"
          pattern={this.pattern()}
          onChange={this.handleChange}
          className="col-md-2 form-control"
        />
      </span>
    );
  }
}

export default FormDatePicker;
